import { effect } from "@chakra-ui/react";
import { IAnalyticsMetrics, IAuditForm, IHookFromOptions, IKeyValue, IRuleEngineReason, IVehicleType, LinkItemProps } from "./types";
import { version } from "../../package.json";

export const LinkItems: Array<LinkItemProps> = [
  {
    name: "Home",
    icon: "fluent:home-12-regular",
    activeIcon: "fluent:home-12-filled",
    to: "/",
    activeRegex: /^\/$/,
    access: ["Hospital Manager", "business_user"],
  },
  // {
  //   name: "Analytics",
  //   icon: "majesticons:analytics-line",
  //   activeIcon: "majesticons:analytics",
  //   to: "/analytics",
  //   activeRegex: /^\/analytics/,
  //   access: ["Hospital Manager", "business_user"],
  // },
  // {
  //   name: "Light House",
  //   icon: "ph:map-trifold",
  //   activeIcon: "ph:map-trifold-fill",
  //   to: "light-house",
  //   activeRegex: /^\/light-house/,
  // },
  {
    name: "Create Case",
    icon: "mdi:hospital-box-outline",
    activeIcon: "mdi:hospital-box",
    to: "create-case",
    activeRegex: /^\/create-case/,
    access: ["Hospital Manager", "business_user"],
  },
  {
    name: "Case List",
    icon: "ph:note-duotone",
    activeIcon: "ph:note-fill",
    to: "case-list",
    activeRegex: /^\/case-list/,
    access: ["Hospital Manager", "business_user"],
  },
  // {
  //   name: "Personnel List",
  //   icon: "ph:user-list",
  //   activeIcon: "ph:user-list-fill",
  //   to: "personnel-list",
  //   activeRegex: /^\/personnel-list/,
  // },
  {
    name: "Pending BTH Slip",
    icon: "ic:baseline-pending-actions",
    activeIcon: "ic:twotone-pending-actions",
    to: "bth-slip",
    activeRegex: /^\/bth-slip/,
    access: ["Hospital Manager", "business_user"],
  },
  {
    name: "Wallet Details",
    icon: "ph:wallet",
    activeIcon: "ph:wallet-fill",
    to: "wallet/outstanding",
    activeRegex: /^\/wallet/,
    access: ["Hospital Manager", "Collection Agent", "business_user"],
  },
  {
    name: "Tutorial",
    icon: "fluent:learning-app-24-regular",
    activeIcon: "fluent:learning-app-24-filled",
    to: "tutorial",
    activeRegex: /^\/tutorial/,
    access: ["Hospital Manager", "Collection Agent", "business_user"],
  },
];

export const ProfileNaveItem = {
  name: "Profile",
  icon: "ph:user",
  activeIcon: "ph:user-bold",
  to: "profile",
  activeRegex: /^\/profile/,
};

export const LogOutNavItem = {
  name: "Logout",
  icon: "ph:power",
  activeIcon: "ph:user-bold",
  to: "/auth/logout",
  activeRegex: /^\/logout/,
};

export const CASE_LISTING_TABLE_CASE_STATUS_COLUMN_COLOR_MAP: {
  [key: string]: string;
} = {
  CANCELLED: "slate",
  SCHEDULED: "green",
  FULFILLED: "green",
  DISPATCHED: "orange",
  UNFULFILLED: "red",
  DRAFT: "primary",
  SUBMITTED: "yellow",
};

export const CASE_LISTING_TABLE_CASE_STATUS_COLUMN_ICON_MAP: {
  [key: string]: string;
} = {
  CANCELLED: "mdi:car-off",
  FULFILLED: "file-icons:donejs",
  SCHEDULED: "mdi:clock-time-three-outline",
  DISPATCHED: "streamline-emojis:police-car-light",
  UNFULFILLED: "bxs:ambulance",
  DRAFT: "primary",
  SUBMITTED: "fluent:vehicle-car-parking-16-filled",
};

export const CASE_LISTING_TABLE_DISPATCH_COLUMN_COLOR_MAP: {
  [key: string]: string;
} = {
  SCHEDULED: "green",
  NOW: "red",
};

export const CASE_LISTING_TABLE_DISPATCH_COLUMN_ICON_MAP: {
  [key: string]: string;
} = {
  FULFILLED: "ph:calendar-fill",
  NOW: "ph:clock-fill",
};

export const CASE_LISTING_TABLE_BILLING_COLUMN_COLOR_MAP: {
  [key: string]: string;
} = {
  BTH: "green",
  BTP: "primary",
};

export const CASE_LISTING_TABLE_BILLING_COLUMN_ICON_MAP: {
  [key: string]: string;
} = {
  BTH: "mingcute:hospital-fill",
  BTP: "fluent:person-24-filled",
};

export const CASE_LISTING_TABLE_BOOKING_COLUMN_COLOR_MAP: {
  [key: string]: string;
} = {
  BOOKING: "green",
  ENQUIRY: "red",
};

export const CASE_LISTING_TABLE_BOOKING_COLUMN_ICON_MAP: {
  [key: string]: string;
} = {
  BOOKING: "",
  ENQUIRY: "",
};

export const CASE_LISTING_TABLE_PLATFORM_COLUMN_COLOR_MAP: {
  [key: string]: string;
} = {
  WEB: "green",
  HM_APP: "red",
  UNKNOWN: "slate",
};

export const CASE_LISTING_TABLE_PLATFORM_COLUMN_ICON_MAP: {
  [key: string]: string;
} = {
  WEB: "mdi:web",
  HM_APP: "ph:device-mobile-fill",
  UNKNOWN: "material-symbols:device-unknown",
};

export const CASE_LISTING_AMBULANCE_COLUMN_COLOR_MAP: {
  [key: string]: string;
} = {
  ALS: "green",
  BLS: "red",
  ASTH: "slate",
};

export const CASE_LISTING_AMBULANCE_COLUMN_ICON_MAP: { [key: string]: string } = {
  ALS: "mdi:web",
  BLS: "ph:device-mobile-fill",
  ASTH: "material-symbols:device-unknown",
};

export const preSelectedFilter = {
  orderStatus: "dispatched",
  bookingType: "ENQUIRY",
};

type PERSONNEL_LIST_STATUS_THEME_TYPE = "ONLINE" | "OFFLINE" | "ON_TRIP";

export const PERSONNEL_LIST_STATUS_THEME: { [key: string]: any } = {
  ONLINE: {
    label: "ONLINE",
    color: "green",
  },
  OFFLINE: {
    label: "OFFLINE",
    color: "slate",
  },
  ON_TRIP: {
    label: "ON_TRIP",
    color: "orange",
  },
};

export const AMBULANCE_TYPE_COLORS: IKeyValue = {
  BLS: "green",
  ALS: "red",
};

export const AMBULANCE_TYPE_ICON: IKeyValue = {
  BLS: "bxs:ambulance",
  ALS: "medical-icon:i-ambulance",
};

export const ROLES_TYPE_COLORS: IKeyValue = {
  BUSINESS_USER: "primary",
  PILOT: "red",
  "HOSPITAL MANAGER": "green",
  "PARTNER OPERATOR": "orange",
  "FLEET MANAGER": "blue",
  "COLLECTION AGENT": "purple",
};

export const ROLES_TYPE_ICON: IKeyValue = {
  BUSINESS_USER: "foundation:torso-business",
  PILOT: "mdi:account-pilot",
  "HOSPITAL MANAGER": "mdi:hospital-box",
  "PARTNER OPERATOR": "material-symbols:partner-exchange",
  "FLEET MANAGER": "mdi:fleet",
  "COLLECTION AGENT": "tdesign:money",
};

export const SYMPTOMS: string[] = [
  "Shortness of breath (rapid breathing)",
  "Chest pain",
  "discomfort in chest",
  "Dizziness",
  "coughing (sometimes with blood)",
  "Fatigue (Loss of Energy)",
  "Irregular heartbeat",
  "pain (in shoulders-arms-neck-jaw-back)",
  "High Blood Pressure",
  "Heart Attack/Cardiac arrest",
  "Fainting",
  "Heart Problems",
  "Cardiology",
  "Swelling(near mouth)",
  "Severe Pain(near mouth)",
  "crack or break in teeth",
  "Blood loss(near mouth)",
  "bluish / Red skin color ( discoloration)",
  "dry cracked skin",
  "History of diabetes",
  "confusion",
  "Low Blood Sugar",
  "palpitation (rapid heart beat)",
  "Diabetes",
  "Sweating",
  "swollen legs",
  "Unconscious",
  "Low Blood Pressure",
  "swelling of face and throat",
  "tongue swelling",
  "swollen lymph nodes",
  "Headache",
  "facial pain",
  "nasal congestion",
  "Ear pain",
  "Difficulty hearing",
  "Lump in Neck",
  "ENT (Ear-Nose-Throat)",
  "Diarrhea / LOOSE STOOLS",
  "Head & Neck Surgery",
  "bruising",
  "Heart Failure",
  "Abdominal pain(especially on upper side)",
  "Pale/Blue Skin (Cynosis)",
  "Abdominal pain (especially on lower side)",
  "weakness",
  "Burning stomach pain",
  "Acidity",
  "Gas",
  "bloating",
  "Jaundice",
  "vomiting blood",
  "Spleen Injury",
  "Liver problems",
  "Pancreatitis",
  "Appendicitis",
  "Hiccups ( Continuos)",
  "Stomach Ulcer / Gastric Ulcer",
  "Stomach Pain",
  "Abdominal pain",
  "Severe Toothache",
  "constipation",
  "Red swollen and painful area of skin that is warm and tender to the touch",
  "ulcer in foot/puss discharge from foot",
  "foul smell from foot",
  "Bulge in the abdomen or groin(pelvic region)",
  "pain at the site of hernia",
  "nipple discharge",
  "Post Surgical Complications",
  "Bleeding",
  "Hernia",
  "Crush injury",
  "Open fractures and major dislocations",
  "Assault / Violence",
  "Accident",
  "History of cancer",
  "Mouth sores",
  "Lump in the neck",
  "Voice hoarseness",
  "neck pain",
  "voice changes",
  "History of liver disease",
  "yellowish skin",
  "bad taste in the mouth",
  "Abdominal swelling",
  "Fluid filled in abdomen",
  "sensitivity in teeth",
  "Dengue/Malaria",
  "inability to urinate",
  "swelling in affected region",
  "Tremors(shaking of hands)",
  "Dislocated jaw",
  "Coughing",
  "History of exposure to poison/toxin",
  "chills",
  "itching",
  "History of burn",
  "blurred vision",
  "thickened nails",
  "frequent infections",
  "High blood sugar",
  "Nausea",
  "Internal Medicine",
  "Covid-19",
  "Electrical Injury",
  "Poisoning",
  "Snake bite",
  "Typhoid",
  "Allergy",
  "Chikenpox/Measles",
  "Asthma",
  "Multiple Organ Failure",
  "Chikungunya",
  "Viral",
  "History of organ transplant",
  "pain at transplant site",
  "flu-like symptoms after transplant",
  "Transplant Region Swelling",
  "Blisters (fluid filled bubble on skin)",
  "Organ Transplant",
  "Blood In Urine",
  "Burning Sensation while Urination",
  "History of kidney disease",
  "frequent urination",
  "Dialysis",
  "Urinary Problems",
  "Kidney Problems",
  "Bladder Problems",
  "Nephrology",
  "numbness (loss of sensation)",
  "sensitivity to light",
  "visual disturbances",
  "stiff neck",
  "trouble speaking",
  "Convulsions (uncontrolled shaking of body)",
  "Neurosurgery",
  "Stroke",
  "Bleeding from Head",
  "Slip Disc",
  "Fits",
  "Neurology",
  "Head Injury",
  "Memory Loss",
  "Unable To Walk",
  "Paralysis",
  "Brain Problems",
  "Siezures",
  "History of pregnancy",
  "Strong & regular contractions",
  "Foot pain and swelling",
  "vaginal discharge",
  "pain in abdomen and lower back",
  "vaginal bleeding",
  "Delivery / Child Birth / Labor",
  "Gynaecology(Pregnancy Related)",
  "Postpartum (after delivery) Complications",
  "blood in stool",
  "weight loss",
  "Lump in the breast",
  "changes in breast shape",
  "redness",
  "bleeding gums",
  "Continuous coughing",
  "Oncology/Cancer",
  "Sudden vision loss",
  "eye pain",
  "discharge from eye",
  "Irritation in eye",
  "redness of eye",
  "watery eyes",
  "double vision",
  "eye swelling",
  "Ophthalmology",
  "Orthopaedics",
  "History of fall/trauma",
  "inability to bear weight",
  "Severe pain in affected region",
  "redness in affected region",
  "joint pain / bone pain",
  "Hip Fracture",
  "Fracture / Broken bone",
  "Knee Surgery / Bone surgery / Joint surgery",
  "Arthritis / Arthralgia",
  "History of thyroid",
  "Vomiting / PUKING",
  "dehydration",
  "Difficulty breathing",
  "Sudden severe testicular pain",
  "Pediatrics",
  "no tears when crying",
  "sunken eyes",
  "chest tightness",
  "Chest Burn / Heart burn",
  "agitation(extreme anger)",
  "Rehabilitation Therapies",
  "Child Cry",
  "Excessive thirst",
  "Wheezing(whistling sound while breathing)",
  "Fits/Seizures",
  "Pain",
  "Child/children",
  "Neo-Natal/New Born Babies",
  "History of injury",
  "Physiotherapy",
  "Deep red or white skin",
  "Cut in body part",
  "severe bleeding from cut",
  "History of facial trauma",
  "bleeding(from face)",
  "Intense fear",
  "delusions",
  "disorganized thinking",
  "History of recent delivery",
  "Thoughts of harming others and self",
  "severe depression",
  "History of alcohol consumption",
  "violent behaviour",
  "loss of reasoning",
  "Seizures",
  "psychosis",
  "hallucination",
  "Choking sensation",
  "throat tightness",
  "Acts/thoughts of self-harm",
  "hopelessness",
  "Addiction",
  "Anxiety/Panic Attack",
  "Choking",
  "Acting Crazy",
  "Psychiatry",
  "coughing with phlegm",
  "coughing blood",
  "Chest",
  "Lung infection",
  "Pulmonology",
  "Tuberculosis",
  "Drowning",
  "Water Besides Lungs/ plural effusion",
  "Pneumonia",
  "Joint pain",
  "swelling near joints",
  "stiffness of joints",
  "limited body movement",
  "redness near joints",
  "Severe back pain",
  "weakness in legs or arms",
  "difficulty walking",
  "Incoordination",
  "Spine Problems",
  "Spine Surgery",
  "History of sports trauma",
  "Sports Medicine",
  "Severe pain (in back or side of body)",
  "Sore throat",
  "difficulty swallowing",
  "fluid discharge from ear",
  "Fever",
  "Painful urination",
  "Kidney Stone",
  "Scrotum Problem",
  "Scrotal Swelling/ Bleeding / Injury",
  "Follow-Up Care",
  "Diagnosis / Medical Examination",
  "Drug Overdose",
  "nose bleed",
  "Bedridden Patient",
  "Cancer Screening",
  "Chemotherapy Transport",
  "Radiation Therapy Transport",
  "Pregnancy-Related Complications",
  "Infectious Diseases (e.g. Flu - COVID 19)",
  "Psychiatric Emergency (e.g. Suicide Attempt)",
  "Behavioral Health Transport (e.g. Schizophrenia)",
  "Pediatric Emergencies",
  "Neonatal Transport (Child < 1 month age )",
  "Hyperthermia (Heat Stroke)",
  "Migraine",
  "Skin rashes",
  "Animal Bite",
  "Injury from violence",
  "Suicide Attempt",
  "Burn",
  "Dental Appointment",
  "Hypothermia",
  "Transport for Surgical Follow-Up",
  "Transport for Medical Imaging (e.g. MRI - CT Scan)",
  "Transport for Physical Therapy",
  "Transport for Occupational Therapy",
  "Post-Surgical Complications (e.g. Infection)",
  "Transport for Blood Transfusion",
  "Transport for Dead Body",
  "Road Traffic Accident (RTA)",
  "Not Breathing",
  "No Pulse",
  "Not Responding",
  "Liver Transplant",
  "Kidney Transplant",
  "Lung Transplant",
];

export const METRICS: IAnalyticsMetrics[] = [
  {
    name: "Order Metrics",
    keys: ["created", "completed", "incomplete", "scheduled"],
    chartType: "text",
    // colorScheme: {
    //   incomplete: 'red.300',
    //   completed: 'green.300',
    // },
  },
  {
    name: "Offer Metrics",
    keys: ["offerMetrics"],
    chartType: "text",
    // colorScheme: {
    //   rejected: 'red.300',
    //   dispatched: 'green.300',
    // },
  },
  {
    name: "Incomplete Metrics",
    keys: ["cancelled", "dispatched", "unfulfilled", "unsettled"],
    colorScheme: {
      cancelled: "red.300",
      dispatched: "green.300",
    },
    chartType: "text",
  },
  {
    name: "Dispatch Metrics",
    keys: ["auto", "manual"],
    chartType: "text",
  },
  {
    name: "Payment Metrics",
    keys: ["settled", "unsettled"],
    chartType: "text",
  },
];

export const MAP_MARKER_THEME: { [key: string]: any } = {
  ONLINE: {
    label: "ONLINE",
    color: "green",
    background: "green.300",
  },
  OFFLINE: {
    label: "OFFLINE",
    color: "grey",
    background: "grey.300",
  },
  ON_TRIP: {
    label: "ON_TRIP",
    color: "blue",
    background: "blue.300",
  },
};

export const COLOR_PLATE_FOR_DRIVER: IKeyValue = {
  SUCCESS: {
    colorSchema: "green",
    color: "green.500",
    bg: "green.100",
  },
  WARNING: {
    colorSchema: "orange",
    color: "orange.500",
    bg: "orange.100",
  },
  GREY: {
    colorSchema: "slate",
    color: "slate.900",
    bg: "slate.500",
  },
  PRIMARY: {
    colorSchema: "primary",
    color: "primary.900",
    bg: "primary.500",
  },
};

export const COLOR_PLATE_FOR_CASE: IKeyValue = {
  SUCCESS: {
    colorSchema: "green",
    color: "green.500",
    bg: "green.50",
  },
  WARNING: {
    colorSchema: "orange",
    color: "orange.500",
    bg: "orange.50",
  },
  GREY: {
    colorSchema: "slate",
    color: "slate.900",
    bg: "slate.50",
  },
  PRIMARY: {
    colorSchema: "primary",
    color: "primary.500",
    bg: "primary.50",
  },
};

export const CITY_COORDINATES: IKeyValue = {
  HYD: {
    lat: 17.385,
    lng: 78.4867,
  },
  BLR: {
    lat: 12.9715987,
    lng: 77.5945627,
  },
  MUM: {
    lat: 19.076,
    lng: 72.8777,
  },
  DLH: {
    lat: 28.6139,
    lng: 77.209,
  },
  AMD: {
    lat: 23.0225,
    lng: 72.5714,
  },
  NMB: {
    lat: 19.033,
    lng: 73.0297,
  },
  PNE: {
    lat: 18.5204,
    lng: 73.8567,
  },
  HSN: {
    lat: 13.0072,
    lng: 76.0967,
  },
  IDR: {
    lat: 22.7196,
    lng: 75.8577,
  },
  KNP: {
    lat: 26.4537,
    lng: 80.3513,
  },
  LCK: {
    lat: 26.8624,
    lng: 81.0203,
  },
  RAI: {
    lat: 21.1579,
    lng: 81.7837,
  },
  FDB: {
    lat: 28.4089,
    lng: 77.3178,
  },
  NOI: {
    lat: 28.5355,
    lng: 77.391,
  },
  GGN: {
    lat: 28.4595,
    lng: 77.0266,
  },
  CHN: {
    lat: 13.0827,
    lng: 80.2707,
  },
  BBS: {
    lat: 20.2961,
    lng: 85.8245,
  },
  KOL: {
    lat: 22.5726,
    lng: 88.3639,
  },
  JMS: {
    lat: 22.8046,
    lng: 86.2029,
  },
  GHT: {
    lat: 26.1158,
    lng: 91.7086,
  },
};

export const MAP_INIT_OBJ: any = {
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
  libraries: ["places"],
};

export const FORM_ERROR_MESSAGE: IKeyValue = {
  "Please type contact person name": "Please type contact person name",
};

// IHookFromOptions
export const InputFieldOptions: any = {
  name: {
    required: FORM_ERROR_MESSAGE["Please type contact person name"],
  },
  number: {
    required: "Please type contact person name",
    minLength: {
      message: "Please type a valid mobile number",
      value: 10,
    },
    pattern: {
      message: "Please type valid mobile number.",
      value: /^[6789]\d{9}/,
    },
  },
};

export const ATTRIBUTE_OPTIONS = [
  { value: "RED", label: "RED OS" },
  { value: "JSD", label: "Justdial" },
  { value: "GGL", label: "Google" },
  { value: "WBS", label: "Website" },
  { value: "WNR", label: "Owner / Driver" },
  { value: "MLB", label: "Ambulance Branding" },
  { value: "WRM", label: "Word of mouth" },
  { value: "ANV", label: "Anvayaa" },
  { value: "TST", label: "Test Call" },
  { value: "WOR", label: "Worley" },
  { value: "TKS", label: "Thyssenkrupp - TKIS" },
  { value: "PER", label: "Percept - PER" },
  { value: "DRY", label: "Dr Reddy - DRY" },
  { value: "FSE", label: "Firstsource - FSE" },
  { value: "ACL", label: "Accel - ACL" },
  { value: "TCS", label: "TCS - TCS" },
  { value: "CEO", label: "Prabhdeep Singh- CEO" },
  { value: "APL", label: "Apple - APL" },
  { value: "HPL", label: "HPCL - HPL" },
  { value: "CHT", label: "Call Health" },
  { value: "NPH", label: "Non Partnered Hospitals" },
  { value: "ACT", label: "ACT" },
  { value: "RZP", label: "RazorPay - RZP" },
  { value: "REK", label: "Rupeek - REK" },
  { value: "GEI", label: "GE India - GE" },
  { value: "PEP", label: "PEPSICO - PEP" },
  { value: "AMZ", label: "AMAZON - AMZ" },
  { value: "CBC", label: "Cyberabad Commissionerate" },
  { value: "WPP", label: "Wire and Plastic Products" },
  { value: "DAL", label: "Dalmia Bharat" },
  { value: "ABB", label: "ABB India Ltd. - ABB" },
  { value: "ACC", label: "Accenture" },
  { value: "BNP", label: "BNP Paribas" },
  { value: "CAP", label: "Capgemini" },
  { value: "FCB", label: "Facebook" },
  { value: "HMC", label: "Hero Moto Corp" },
  { value: "KTK", label: "Kotak Mahindra" },
  { value: "KPM", label: "KPMG" },
  { value: "MRH", label: "Marsh" },
  { value: "PGC", label: "Procter & Gamble Company" },
  { value: "SNP", label: "Sonepar Group" },
  { value: "MST", label: "Microsoft" },
  { value: "ANB", label: "Airbnb" },
  { value: "AEX", label: "American Express" },
  { value: "COC", label: "Coca Cola" },
  { value: "ERF", label: "Enterprise Referral" },
  { value: "UNI", label: "UNICEF Hyderabad" },
  { value: "DBAG", label: "Deutsche Bank AG Mumbai" },
  { value: "IPL", label: "Indegene Pvt. Ltd." },
  { value: "EH", label: "Even Health" },
  { value: "WLC", label: "Welldercare" },
  { value: "HDFC", label: "HDFC-ERGO" },
  { value: "GDJ", label: "Godrej Industries" },
  { value: "PINF", label: "PIPELINE INFRA" },
  { value: "EKN", label: "Ekincare" },
  { value: "ZMT", label: "Zomato" },
  { value: "CTRLS", label: "CtrlS" },
  { value: "SBE", label: "Standby Event" },
  { value: "REDFS00001", label: "Field Sales - Kanpur" },
  { value: "REDFS00002", label: "Field Sales - Lucknow" },
  { value: "REDFS00003", label: "Field Sales - Indore" },
  { value: "REDFS00004", label: "Field Sales - Raipur" },
  { value: "REDFS00005", label: "Field Sales - AMD" },
  { value: "REDFS00006", label: "Field Sales - Bangalore" },
  { value: "REDFS00007", label: "Field Sales - Hyderabad" },
  { value: "REDFS00008", label: "Field Sales - Mumbai" },
  { value: "REDFS00009", label: "Field Sales - Pune" },
  { value: "REDFS00010", label: "Field Sales - Chennai" },
  { value: "AIRCARGO0001", label: "Air Cargo" },
];

export const VEHICLE_TYPE: IVehicleType[] = [
  {
    value: "ALS",
    label: "ALS",
    icon: "mdi:car-hatchback",
    fare: 0,
    isDisabled: false,
    variant: [
      {
        value: "ALS",
        label: "ALS Any",
        icon: "ph:taxi-bold",
        fare: 0,
        isDisabled: false,
        parent: "ALS",
        message: "All ALS ambulances, marked as Elite, Supreme, and Standard, are eligible for dispatch.",
      },
      {
        value: "alsElite",
        label: "ALS Elite",
        icon: "ph:taxi-bold",
        fare: 0,
        isDisabled: false,
        parent: "ALS",
        message: "Ambulances marked as ALS Elite are the only ones eligible for dispatch.",
      },
      {
        value: "alsStandard",
        label: "ALS Standard",
        icon: "ph:taxi-bold",
        fare: 0,
        isDisabled: false,
        parent: "ALS",
        message: "Ambulances marked as ALS Standard are the only ones eligible for dispatch.",
      },
      {
        value: "alsSupreme",
        label: "ALS Supreme",
        icon: "ph:taxi-bold",
        fare: 0,
        isDisabled: false,
        parent: "ALS",
        message: "Ambulances labeled as ALS Supreme are the only ones eligible for dispatch.",
      },
    ],
  },
  {
    value: "BLS",
    label: "BLS",
    icon: "humbleicons:car",
    fare: 0,
    isDisabled: false,
    variant: [
      {
        value: "BLS",
        label: "BLS Any",
        icon: "ph:taxi-bold",
        fare: 0,
        isDisabled: false,
        parent: "BLS",
        message: "All BLS Or ALS ambulances, marked as Elite, Supreme, and Standard, are eligible for dispatch.",
      },
      {
        value: "blsElite",
        label: "BLS Elite",
        icon: "ph:taxi-bold",
        fare: 0,
        isDisabled: true,
        parent: "BLS",
        message: "Ambulances marked as BLS Elite are the only ones eligible for dispatch.",
      },
      {
        value: "blsStandard",
        label: "BLS Standard",
        icon: "ph:taxi-bold",
        fare: 0,
        isDisabled: false,
        parent: "BLS",
        message: "Ambulances marked as BLS Standard are the only ones eligible for dispatch.",
      },
      {
        value: "blsSupreme",
        label: "BLS Supreme",
        icon: "ph:taxi-bold",
        fare: 0,
        isDisabled: false,
        parent: "BLS",
        message: "Ambulances labeled as BLS Supreme are the only ones eligible for dispatch.",
      },
    ],
  },
  {
    value: "ASTH",
    label: "Asth",
    icon: "dashicons:car",
    fare: 0,
    isDisabled: true,
    message: "Ambulances marked as ASTH are only eligible for dispatch.",
  },
  {
    value: "NEO",
    label: "NEO",
    icon: "uil:car-sideview",
    fare: 0,
    isDisabled: true,
    message: "Ambulances marked as NEO are only eligible for dispatch.",
  },
];

export const ADDON_ICONS: IKeyValue = {
  WC: "ph:wheelchair",
  OMK: "ph:face-mask",
  DEF: "ph:pulse-bold",
  FFC: "ph:pulse-bold",
  PPE: "ph:first-aid-kit-bold",
  DBS: "ph:bed-bold",
  DVT: "ph:bandaids",
  FBX: "ph:cube-bold",
  HMK: "ph:person",
  PARAMEDIC: "ph:syringe",
  HEL: "ph:lifebuoy",
  RALV: "ph:lifebuoy",
  O2: "ph:face-mask",
  MET: "ph:cube-bold",
  IFP: "ph:cube-bold",
  RASV: "ph:cube-bold",
  SYG: "ph:cube-bold",
  VFB: "ph:first-aid-kit-bold",
  DBC: "ion:body-outline",
  FCT: "healthicons:doctor-female-outline",
  MCT: "healthicons:doctor-male-outline",
  NUR: "healthicons-nurse-outline",
  DOC: "hugeicons:doctor-03",
  MED: "healthicons:medicines-outline",
};

export const CASE_TYPE: any = {
  "LAMA/DAMA": "Case where both pickup and drop is source and partner hospital combination",
  TRANSFER: "Case where neither pickup nor drop is the source",
  OUTBOUND: "Case where source is selected as drop location",
  INBOUND: "Case where source is selected as pickup location",
};

export const BILL_TO_OPTIONS = [
  {
    label: "Bill To Client",
    value: "BTC",
  },
  {
    label: "Bill To Patient",
    value: "BTP",
  },
];

export const OVERRIDE_COMMENTS = [
  { value: "nuclear_medicine", label: "Nuclear Medicine" },
  { value: "inter_branch_transfer", label: "Inter Branch Transfer" },
  { value: "fixed_rate", label: "Fixed Rate" },
  { value: "air_ambulance", label: "Air Ambulance" },
  { value: "air_cargo", label: "Air Cargo" },
  { value: "other", label: "Other" },
];

export const GENDER_OPTIONS = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "other",
    label: "Other",
  },
];

export interface IServiceTypeMap {
  [key: string]: {
    label: string;
    value: string;
    effect?: {
      dispatchType?: string;
      serviceType?: string;
      isOverridenPrice?: boolean;
      overridenComments?: string;
    };
  };
}

export const DEPARTMENT_OPTIONS = [
  { label: "Cardiology", value: "Cardiology" },
  { label: "Dentistry", value: "Dentistry" },
  { label: "Dermatology", value: "Dermatology" },
  { label: "Diabetology", value: "Diabetology" },
  { label: "Endocrinology", value: "Endocrinology" },
  { label: "ENT (Ear-Nose-Throat)", value: "ENT (Ear-Nose-Throat)" },
  { label: "Gastroenterology", value: "Gastroenterology" },
  { label: "General Surgery", value: "General Surgery" },
  { label: "Head & Neck Surgery", value: "Head & Neck Surgery" },
  { label: "Hepatology", value: "Hepatology" },
  { label: "Internal Medicine", value: "Internal Medicine" },
  { label: "Multiorgan Transplant", value: "Multiorgan Transplant" },
  { label: "Nephrology", value: "Nephrology" },
  { label: "Neurology", value: "Neurology" },
  { label: "Obstetrics & Gynaecology", value: "Obstetrics & Gynaecology" },
  { label: "Oncology", value: "Oncology" },
  { label: "Ophthalmology", value: "Ophthalmology" },
  { label: "Orthopaedics", value: "Orthopaedics" },
  { label: "Pediatrics", value: "Pediatrics" },
  { label: "Neonatology", value: "Neonatology" },
  { label: "Physiotherapy", value: "Physiotherapy" },
  { label: "Plastic Surgery", value: "Plastic Surgery" },
  { label: "Psychiatry", value: "Psychiatry" },
  { label: "Pulmonology", value: "Pulmonology" },
  { label: "Rheumatology", value: "Rheumatology" },
  { label: "Spine Surgery", value: "Spine Surgery" },
  { label: "Sports Medicine", value: "Sports Medicine" },
  { label: "Urology", value: "Urology" },
  { label: "Emergency", value: "Emergency" },
  { label: "Multispeciality", value: "Multispeciality" },
  { label: "Dialysis", value: "Dialysis" },
  { label: "Liver Transplant", value: "Liver Transplant" },
  { label: "Kidney Transplant", value: "Kidney Transplant" },
  { label: "Lung Transplant", value: "Lung Transplant" },
  { label: "Burn Unit", value: "Burn Unit" },
];

export const SERVICE_TYPE_MAP: IServiceTypeMap = {
  STANDARD: {
    label: "Standard Ambulance",
    value: "STANDARD",
    effect: {
      serviceType: "STANDARD",
    },
  },
  AIR_AMBULANCE: {
    label: "Air Ambulance",
    value: "AIR_AMBULANCE",
    effect: {
      dispatchType: "SCHEDULED",
      serviceType: "AIR_AMBULANCE",
      isOverridenPrice: true,
      overridenComments: "Air Ambulance",
    },
  },
  AIR_CARGO: {
    label: "Air Cargo",
    value: "AIR_CARGO",
    effect: {
      dispatchType: "SCHEDULED",
      serviceType: "AIR_CARGO",
      isOverridenPrice: true,
      overridenComments: "Air Cargo",
    },
  },
  CREMATION: {
    label: "Cremation",
    value: "CREMATION",
    effect: {
      dispatchType: "SCHEDULED",
      serviceType: "CREMATION",
      isOverridenPrice: true,
      overridenComments: "Cremation",
    },
  },
  HOME_FREEZER_BOX: {
    label: "Home Freezer Box",
    value: "HOME_FREEZER_BOX",
    effect: {
      dispatchType: "SCHEDULED",
      serviceType: "HOME_FREEZER_BOX",
      isOverridenPrice: true,
      overridenComments: "Home Freezer Box",
    },
  },
  ORGAN_TRANSPORTATION: {
    label: "Organ Transportation",
    value: "ORGAN_TRANSPORTATION",
    effect: {
      dispatchType: "SCHEDULED",
      serviceType: "ORGAN_TRANSPORTATION",
      isOverridenPrice: true,
      overridenComments: "Organ Transport",
    },
  },
  EMBALMING: {
    label: "Embalming",
    value: "EMBALMING",
    effect: {
      dispatchType: "SCHEDULED",
      serviceType: "EMBALMING",
      isOverridenPrice: true,
      overridenComments: "Embalming",
    },
  },
  DEAD_BODY: {
    label: "Dead Body",
    value: "DEAD_BODY",
    effect: {
      // dispatchType: "SCHEDULED",
      serviceType: "DEAD_BODY",
    },
  },
  SBE: {
    label: "Standby Event",
    value: "SBE",
    effect: {
      dispatchType: "SCHEDULED",
      serviceType: "SBE",
      isOverridenPrice: true,
      overridenComments: "Standby Event",
    },
  },
  TRAIN_AMBULANCE: {
    label: "Train Ambulance",
    value: "TRAIN_AMBULANCE",
    effect: {
      dispatchType: "SCHEDULED",
      serviceType: "TRAIN_AMBULANCE",
    },
  },
};

export type TServiceTypes = keyof typeof SERVICE_TYPE_MAP;
export type TTServiceTypes =
  | "STANDARD"
  | "AIR_AMBULANCE"
  | "AIR_CARGO"
  | "EMBALMING"
  | "CREMATION"
  | "HOME_FREEZER_BOX"
  | "ORGAN_TRANSPORTATION"
  | "DEAD_BODY"
  | "SBE"
  | "TRAIN_AMBULANCE"
  | "EMBALMING";

export const AUTO_DISPATCH_ALLOWED_KEY_BTH = [
  "addons",
  "orderId",
  "assignmentType",
  "billToClient",
  "isOverridenPrice",
  "bookingType",
  "city",
  "dispatchType",
  "entityRequired",
  "orderClassification",
  "requestedBy",
  "requestedFor",
  "serviceType",
  "waypoints",
  "costToOwner",
  "stanplusMargin",
  "scheduledDdtm",
  "fleetTypeRequested",
  "attributedTo",
  "dispatchBy",
  "isLamaDamaSelected",
  "isCovidCase",
];

export const AUTO_DISPATCH_ALLOWED_KEY_BTP = [...AUTO_DISPATCH_ALLOWED_KEY_BTH, "fareAgreed"];

export const MANUAL_DISPATCH_KEY = ["orderId", "entityRequired", "bookingType"];

export const ambulanceMapper: any = {
  alsElite: "ALS Elite",
  alsStandard: "ALS Standard",
  alsSupreme: "ALS Supreme",
  blsElite: "BLS Elite",
  blsStandard: "BLS Standard",
  blsSupreme: "BLS Supreme",
  als: "ALS",
  bls: "BLS",
  BLS: "BLS",
  ALS: "ALS",
  default: "-",
  ALS_STANDARD: "ALS Standard",
  ALS_ELITE: "ALS Elite",
  ALS_SUPREME: "ALS Supreme",
  BLS_STANDARD: "BLS Standard",
  BLS_ELITE: "BLS Elite",
  BLS_SUPREME: "BLS Supreme",
};

export const AMBULANCE_MAPPER_KEY: any = {
  ALS_ELITE: "alsElite",
  ALS_STANDARD: "alsStandard",
  ALS_SUPREME: "alsSupreme",
  BLS_ELITE: "blsElite",
  BLS_STANDARD: "blsStandard",
  BLS_SUPREME: "blsSupreme",
  ALS: "als",
  BLS: "bls",
  alsElite: "alsElite",
  alsStandard: "alsStandard",
  alsSupreme: "alsSupreme",
  blsElite: "blsElite",
  blsStandard: "blsStandard",
  blsSupreme: "blsSupreme",
  als: "als",
  bls: "bls",
};

export const ORDER_CREATION_TEMPLATE = [
  {
    name: "Standard Ambulance",
    key: "STANDARD",
    src: "/assets/template/ambulance.png",
    isActive: true,
  },
  {
    name: "Dead Body",
    key: "DEAD_BODY",
    src: "/assets/template/dead-body.png",
    isActive: true,
  },
  {
    name: "Home Freezer Box",
    key: "HOME_FREEZER_BOX",
    src: "/assets/template/freezer.png",
    isActive: true,
  },
  {
    name: "Air Cargo",
    key: "AIR_CARGO",
    src: "/assets/template/airplane.png",
    isActive: true,
  },
  {
    name: "Air Ambulance",
    key: "AIR_AMBULANCE",
    src: "/assets/template/air-ambulance.png",
    isActive: true,
  },
  {
    name: "Cremation",
    key: "CREMATION",
    src: "/assets/template/cremation.png",
    isActive: true,
  },

  {
    name: "Train Ambulance",
    key: "TRAIN_AMBULANCE",
    src: "/assets/template/train-ambulance.png",
    isActive: true,
  },
  {
    name: "Organ Transport",
    key: "ORGAN_TRANSPORTATION",
    src: "/assets/template/organ.png",
    isActive: true,
  },
  {
    name: "Standby Event",
    key: "SBE",
    src: "/assets/template/standby.png",
    isActive: true,
  },
  {
    name: "Embalming",
    key: "EMBALMING",
    src: "/assets/template/body.png",
    isActive: false,
  },
];

export const PRICING_KEY = `pricing-entity`;
export const DISPATCH_VEHICLE_KEY = `dispatch-entity`;
export const NEARBY_SEARCH_KEY = `dispatch-entity-nearby`;
export const DEFAULT_VEHICLE_SEARCH_RADIUS = 50000;

export const AMBULANCE_COLOR_PLATE: { [key: string]: any } = {
  BLS: "orange.400",
  ALS: "red.400",
  ALS_ELITE: "red.400",
  ALS_STANDARD: "red.400",
  ALS_SUPREME: "red.400",
  BLS_ELITE: "orange.400",
  BLS_STANDARD: "orange.400",
  BLS_SUPREME: "orange.400",
};

export const RULE_VERBOSE_KEY_MAPPER: any = {
  ambulanceOperational: "Ambulance Operational",
  ambulanceNearby: "Ambulance Nearby",
  pilotRostered: "Pilot Rostered",
  paramedicRostered: "Paramedic Rostered",
  pilotAvailable: "Pilot Available",
  ambulanceCanServeAsAmbulanceRequested: "Ambulance Can Serve As Ambulance Requested",
  ambulanceCanServeCaseContractually: "Ambulance Can Serve Case Contractually",
};

export const Ambulance_non_operational: IRuleEngineReason = {
  title: "Ambulance Operational",
  description: {
    render: () => ``,
  },
  status: {
    type: "Reject",
    tooltip: " Ambulance is not Operational. ",
  },
};

export const SCHEDULE_MINUTES = 55;

export const NO_DISPATCH_SERVICE_TYPE = ["TRAIN_AMBULANCE", "AIR_CARGO", "AIR_AMBULANCE"];
export const TAG_MAP = [
  { label: "IP signed copy", value: "IP_SIGNED_COPY" },
  { label: "Payment receipt", value: "PAYMENT_RECEIPT" },
  { label: "User photo", value: "USER_PHOTO" },
];

export const MAX_FILE_SIZE = import.meta.env.VITE_MAX_FILE_SIZE;

export const IMAGE_ALLOWED_TYPE = [".png", ".jpeg", ".jpg"];
export const IMAGE_ALLOWED_TYPE_TEXT = ["png", "jpeg", "jpg"];
export const FILE_ALLOWED_TYPE = [".pdf"];

export const ALLOWED_FILE_TYPES = ["png", "jpeg", "jpg", "pdf"];

export const MAX_DOCUMENT_COUNT = 5;

export const DynamicFormData: IAuditForm[] = [
  {
    key: "isGPSEnabled",
    label: "Red Health GPS enabled?",
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    required: true,
    activeFor: ["ALS", "BLS", "alsElite", "alsStandard", "alsSupreme", "blsElite", "blsStandard", "blsSupreme"],
    initialValue: "Yes",
    widgetProps: { style: { width: "100%" } },
  },
  {
    key: "isRegistrationSame",
    label: "Is registration number same as dispatch details?",
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    required: true,
    activeFor: ["ALS", "BLS", "alsElite", "alsStandard", "alsSupreme", "blsElite", "blsStandard", "blsSupreme"],
    initialValue: "Yes",
  },
  {
    key: "fuelLevel",
    label: "Fuel Level",
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Below 25 %", value: "Below 25 %" },
      { label: "25-50 %", value: "25-50 %" },
      { label: "50-75 %", value: "50-75 %" },
      { label: "Above 75 %", value: "Above 75 %" },
    ],
    required: true,
    activeFor: ["ALS", "BLS", "alsElite", "alsStandard", "alsSupreme", "blsElite", "blsStandard", "blsSupreme"],
    initialValue: "25-50 %",
  },
  {
    key: "isACWorking",
    label: "Is AC working effectively?",
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Working Fine", value: "Working Fine" },
      { label: "Not Effective", value: "Not Effective" },
    ],
    required: true,
    activeFor: ["ALS", "BLS", "alsElite", "alsStandard", "alsSupreme", "blsElite", "blsStandard", "blsSupreme"],
    initialValue: "Not Effective",
  },
  {
    key: "isAmbulanceClean",
    label: "Ambulance Cleanliness",
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Excellent", value: "Excellent" },
      { label: "Good", value: "Good" },
      { label: "Fair", value: "Fair" },
      { label: "Poor", value: "Poor" },
    ],
    required: true,
    activeFor: ["ALS", "BLS", "alsElite", "alsStandard", "alsSupreme", "blsElite", "blsStandard", "blsSupreme"],
    initialValue: "Good",
  },
  {
    key: "isBranding",
    label: "Branding",
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Full Red Branded", value: "Full Red Branded" },
      { label: "Partial Red Branded", value: "Partial Red Branded" },
      { label: "Door Red Branded", value: "Door Red Branded" },
      { label: "Other Branded", value: "Other Branded" },
      { label: "Unbranded", value: "Unbranded" },
    ],
    required: true,
    activeFor: ["ALS", "BLS", "alsElite", "alsStandard", "alsSupreme", "blsElite", "blsStandard", "blsSupreme"],
    initialValue: "Partial Red Branded",
  },
  {
    key: "isMonitorPresent",
    label: `Is the 'Monitor (Defibrillator)' present and functional in the ambulance?`,
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes, it is present and functional", value: "Yes, it is present and functional" },
      {
        label: "Yes, it is present but not functional",
        value: "Yes, it is present but not functional",
      },
      { label: "No, it is not present", value: "No, it is not present" },
    ],
    required: true,
    initialValue: "Yes, it is present but not functional",
    activeFor: ["ALS", "alsElite", "alsStandard", "alsSupreme"],
  },
  {
    key: "isSuctionApparatusAvailable",
    label: `Is the 'Suction Apparatus' present in the ambulance, and is it functional?`,
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes, it is present and functional", value: "Yes, it is present and functional" },
      {
        label: "Yes, it is present but not functional",
        value: "Yes, it is present but not functional",
      },
      { label: "No, it is not present", value: "No, it is not present" },
    ],
    required: true,
    initialValue: "Yes, it is present but not functional",
    activeFor: ["ALS", "alsElite", "alsStandard", "alsSupreme"],
  },
  {
    key: "isVentilatorPresent",
    label: `Is the 'Ventilator' present in the ambulance, and is it functional?`,
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes, it is present and functional", value: "Yes, it is present and functional" },
      {
        label: "Yes, it is present but not functional",
        value: "Yes, it is present but not functional",
      },
      { label: "No, it is not present", value: "No, it is not present" },
    ],
    required: true,
    initialValue: "Yes, it is present but not functional",
    activeFor: ["ALS", "alsElite", "alsStandard", "alsSupreme"],
  },
  {
    key: "isInfusionPumpPresent",
    label: `Is the 'Infusion Pump' present in the ambulance, and is it functional?`,
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes, it is present and functional", value: "Yes, it is present and functional" },
      {
        label: "Yes, it is present but not functional",
        value: "Yes, it is present but not functional",
      },
      { label: "No, it is not present", value: "No, it is not present" },
    ],
    required: true,
    initialValue: "Yes, it is present but not functional",
    activeFor: ["ALS", "alsElite", "alsStandard", "alsSupreme"],
  },
  {
    key: "isTwoOxygenCylindersPresent",
    label: `Are there 'Two Oxygen Cylinders' and 'Oxygen face mask with reservoir bag' present in the ambulance?`,
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    required: true,
    initialValue: "No",
    activeFor: ["ALS", "alsElite", "alsStandard", "alsSupreme"],
  },
  {
    key: "isSpinalBoardAndStretcherPresent",
    label: `Is the 'Spinal Board' and 'Stretcher' present in the ambulance, and is it functional?*`,
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes, it is present and functional", value: "Yes, it is present and functional" },
      {
        label: "Yes, it is present but not functional",
        value: "Yes, it is present but not functional",
      },
      { label: "No, it is not present", value: "No, it is not present" },
    ],
    required: true,
    initialValue: "Yes, it is present but not functional",
    activeFor: ["ALS", "alsElite", "alsStandard", "alsSupreme"],
  },
  {
    key: "isAmbulanbagLaryngoscopePresent",
    label: `Is the 'Ambu bag' and 'Laryngoscope' present in the ambulance, and is it functional?`,
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes, it is present and functional", value: "Yes, it is present and functional" },
      {
        label: "Yes, it is present but not functional",
        value: "Yes, it is present but not functional",
      },
      { label: "No, it is not present", value: "No, it is not present" },
    ],
    required: true,
    initialValue: "Yes, it is present but not functional",
    activeFor: ["ALS", "alsElite", "alsStandard", "alsSupreme"],
  },
  {
    key: "isPulseOximeterPresent",
    label: `Is the 'Pulse Oximeter' 'Glucometer' 'Thermometer' and 'BP Apparatus' present in the ambulance, and is it functional?`,
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes, it is present and functional", value: "Yes, it is present and functional" },
      {
        label: "Yes, it is present but not functional",
        value: "Yes, it is present but not functional",
      },
      { label: "No, it is not present", value: "No, it is not present" },
    ],
    required: true,
    initialValue: "Yes, it is present but not functional",
    widgetProps: { style: { width: "100%" } },
    activeFor: ["ALS", "alsElite", "alsStandard", "alsSupreme"],
  },
  {
    key: "isOxygenPressureAbove80",
    label: `Is 'Oxygen Pressure' is above '80' and '130' for 'Short Trip' and 'Long Trip' respectively?`,
    widget: "radio-group",
    forwardRef: true,
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
    required: true,
    initialValue: "No",
    activeFor: ["ALS", "alsElite", "alsStandard", "alsSupreme"],
  },
  {
    key: "vehicleImageGroup",
    label: `Upload Ambulance And Stuff Images`,
    widget: "upload-group",
    forwardRef: true,
    options: [],
    uploadProps: [
      {
        key: "ambulanceImageEquipmentImage",
        label: "Ambulance Equipment",
        required: true,
        initialValue: "",
        forwardRef: true,
      },
      {
        key: "ambulanceImageFrontImage",
        label: "Ambulance Front",
        required: true,
        initialValue: "",
        forwardRef: true,
      },
      {
        key: "ambulanceImageRightSideImage",
        label: "Ambulance Right Side",
        required: true,
        initialValue: "",
        forwardRef: true,
      },
      {
        key: "ambulanceImageLeftSideImage",
        label: "Ambulance Left Side",
        required: true,
        initialValue: "",
        forwardRef: true,
      },
      {
        key: "ambulanceImageStuffImage",
        label: "Ambulance Stuff",
        required: true,
        initialValue: "",
        forwardRef: true,
      },
    ],
    required: true,
    initialValue: "No",
    activeFor: ["ALS", "BLS", "alsElite", "alsStandard", "alsSupreme", "blsElite", "blsStandard", "blsSupreme"],
  },
  {
    key: "finalRemarks",
    label: `RM/HM Final Remarks.`,
    widget: "textarea",
    forwardRef: true,
    options: [],
    required: false,
    initialValue: "No",
    activeFor: ["ALS", "BLS", "alsElite", "alsStandard", "alsSupreme", "blsElite", "blsStandard", "blsSupreme"],
  },
];

export const AUDIT_SUBMIT_KEY = [
  "isGPSEnabled",
  "isRegistrationSame",
  "fuelLevel",
  "isACWorking",
  "isAmbulanceClean",
  "isBranding",
  "isMonitorPresent",
  "isSuctionApparatusAvailable",
  "isVentilatorPresent",
  "isInfusionPumpPresent",
  "isTwoOxygenCylindersPresent",
  "isSpinalBoardAndStretcherPresent",
  "isAmbulanbagLaryngoscopePresent",
  "isPulseOximeterPresent",
  "isOxygenPressureAbove80",
  "newRegistrationNumber",
  "ambType",
  "orderId",
  "updatedBy",
];

export const HM_CAN_DISPATCH_SERVICE_TYPE = ["STANDARD", "DEAD_BODY", "HOME_FREEZER_BOX", "CREMATION", "ORGAN_TRANSPORTATION", "SBE", "EMBALMING"];
export const GLOBAL_SEARCH_SERVICE_TYPE = ["AIR_AMBULANCE", "AIR_CARGO"];

export const X_DISTANCE = 100000;

export const PLATFORM_OBJ = {
  platform: "HM-App",
  version,
};

export const PATIENT_INFO_CONSTANT = {
  AGE: {
    max: 120,
    min: 1,
  },
  WEIGHT: {
    max: 1000,
    min: 0,
  },
  HEIGHT: {
    max: {
      ft: 10,
      inch: 12,
    },
    min: {
      ft: 1,
      inch: 0,
    },
  },
};
export const TUTORIAL_DATA = [
  {
    id: 7,
    isNew: true,
    url: "https://drive.google.com/file/d/1efu4B_0larricJZDto-m5JVJdHXu11Qh/preview",
    name: "How to select accurate pickup and drop location on Map ?",
    tag: ["Select on Map"],
  },
  {
    id: 1,
    isNew: true,
    url: "https://drive.google.com/file/d/1cXEdGEdFWqYhF7nOZb4xnlOOH6rztOfA/preview",
    name: "How to Pay your outstanding?",
    tag: ["Wallet"],
  },
  {
    id: 2,
    isNew: true,
    url: "https://drive.google.com/file/d/1aeJJ-X8KB9nBxDEzKAMmCkkLoOQwoJs_/preview",
    name: "How to do Force Dispatch or On Hold",
    tag: ["Force Dispatch", "On Hold"],
  },
  {
    id: 3,
    isNew: true,
    url: "https://drive.google.com/file/d/1ukQQcIs7gcEIJhowLH0wzhNYPIyUil3K/preview",
    name: "How to  Audit a Vehicle?",
    tag: ["Audit"],
  },
  {
    id: 4,
    isNew: true,
    url: "https://drive.google.com/file/d/1sV2fUGglwrs9x8VkSvF9noprCB5_3hyW/preview",
    name: "How to create a  Booking and  Assign a vehicle?",
    tag: ["Booking", "Assignment"],
  },
  {
    id: 5,
    isNew: true,
    url: "https://drive.google.com/file/d/1Cql3VtBgRsgdCnHmmQbsm6xVS20ttMtd/preview",
    name: " How to Upload Bill to Hospital slip or upload  Other Document Upload",
    tag: ["Document Upload", "BTH Slip"],
  },
  {
    id: 6,
    isNew: true,
    url: "https://drive.google.com/file/d/17yleiqvYyr8u71rLK7kHiKWIThpPybp5/preview",
    name: "How to send Document Link or Cancel Case",
    tag: ["Send Document Link", "Cancel Case"],
  },
];

export const MAP_STYLE_NORMAL = [
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ visibility: "simplified" }],
  },
  {
    featureType: "landscape",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }],
  },
  // You can add more styles as needed
];

export const MAP_STYLE_WINTER = [
  {
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }],
  },
  {
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [{ color: "#f5f5f5" }],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [{ color: "#bdbdbd" }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#dadada" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#c9c9c9" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
];

export const ENABLED_AUDIT_SERVICE_TYPE = ["STANDARD", "AIR_AMBULANCE", "TRAIN_AMBULANCE"];
export const ENABLED_USER_ROLES_FOR_ONLINE_PAY = ["Hospital Manager", "aom"];
export const MAPPING_FOR_USER_ROLES_FOR_ONLINE_PAY = {"Hospital Manager": "HM", "aom":"AOM"};
