// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { getProperty } from "dot-prop";
import { FILE_ALLOWED_TYPE, IMAGE_ALLOWED_TYPE, METRICS } from "../config/constant";
import { IKeyValue, IPlaceAddress } from "../config/types";
import { chain, concat, has, keys, map, omit, pickBy, sortBy } from "lodash";
import dayjs from "dayjs";

const TOAST_STYLE = { fontSize: 12, fontWeight: 800, margin: 10, borderRadius: 4 };

export const showErrorToast = (message: string, duration?: number) => {
  // toast.error(message, {
  //   position: "top-center",
  //   autoClose: 5000,
  //   hideProgressBar: true,
  //   closeOnClick: true,
  //   pauseOnHover: false,
  //   draggable: true,
  //   progress: undefined,
  //   theme: "colored",
  //   style: TOAST_STYLE,
  // });

  if (typeof message !== "string") {
    message = "Un-Serialized  Message.";
  }

  toast.error(message, {
    duration: duration || 1500,
    position: "top-center",
    style: TOAST_STYLE,
  });
};

export const showSuccessToast = (message: string, duration?: number) => {
  // toast.success(message, {
  //   position: "top-center",
  //   autoClose: 1000,
  //   hideProgressBar: true,
  //   closeOnClick: true,
  //   pauseOnHover: false,
  //   draggable: false,
  //   progress: undefined,
  //   theme: "colored",
  //   style: TOAST_STYLE,
  // });

  if (typeof message !== "string") {
    message = "Un-Serialized Message.";
  }

  toast.success(message, {
    position: "top-center",
    duration: duration ? duration : 1500,
    style: TOAST_STYLE,
  });
};

export const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  } as T;
};

export const showSuccessToastV2 = debounce(showSuccessToast, 300);

export const isValueInvalid = (value: any) => value === null || value === undefined || value === "";

export const getQueryFromObj = (params: any) => {
  const cpy: { [key: string]: any } = params;
  const query =
    "?" +
    Object.keys(params)
      .filter(key => cpy[key] !== undefined || cpy[key] !== null)
      .map(key => `${key}=${encodeURIComponent(cpy[key])}`)
      .join("&");
  return query;
};

export function removeNullAndEmptyStrings(obj: any) {
  const entries = Object.entries(obj).filter(([_, value]) => value !== null && value !== "");
  return Object.fromEntries(entries);
}

export const autoLogout = (duration?: number) => {
  // TODO: need to move to constant file.
  const triggerLogout = duration || 5000;

  setTimeout(() => {
    window.location.pathname = "/auth/logout";
  }, triggerLogout);
};

export function objectToQueryString(params: { [key: string]: any }): string {
  return Object.keys(params).reduce(
    (acc, key) => (params[key] !== undefined || params.key !== null ? acc.concat(`${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}&`) : acc),
    "?"
  );
}

export function objectToQueryStringOmitEmpty(params: { [key: string]: any }): string {
  return Object.keys(params).reduce((acc, key) => (params[key] ? acc.concat(`${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}&`) : acc), "?");
}

export const getTimeDiff = (epoch: number, isSecondsData?: boolean): string => {
  const now = new Date();
  const then = new Date(Number(epoch));

  if (!Number.isFinite(then.getTime())) {
    return "";
  }

  const diffInMinutes = isSecondsData ? Math.floor((now.getTime() - then.getTime()) / 1000 / 60) : Math.ceil((now.getTime() - then.getTime()) / 1000 / 60);
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  const seconds = Math.ceil((now.getTime() - then.getTime()) / 1000 / 60 / 60);

  let statusText = isSecondsData ? "" : "Since ";
  if (hours > 0) {
    statusText += `${hours} hour `;
  }
  if (minutes > 0) {
    statusText += `${minutes} min`;
  }
  if (seconds > 0 && isSecondsData) {
    statusText += ` ${seconds % 60} sec`;
  }
  if (statusText === "Since ") {
    statusText += "just now";
  }
  return statusText;
};

export const convertPrice = (amount: any): any => {
  if (isNaN(amount) || amount === undefined || amount === null) return "-";

  return parseInt(parseFloat((amount / 100).toString()).toFixed());
};

export const convertPriceV2 = (amount: any): any => {
  if (isNaN(amount) || amount === undefined || amount === null) return "-";

  return parseInt(parseFloat((amount / 100).toString()).toFixed(2));
};

export function convertTo(a: any) {
  if (!a) return 0;
  const amount = parseFloat(a);
  return amount * 100;
}

export const valueExtractor = (data: any, key: string) => {
  const val = getProperty(data, key);
  return val ? val : "0";
};

export const mountMetricsData = (data: any) => {
  const processedData: any[] = [];
  return new Promise((resolve, reject) => {
    try {
      METRICS.forEach(itm => {
        const { keys, ...rest } = itm;
        const val: IKeyValue = {};

        keys.forEach((paths: string) => {
          val[paths] = valueExtractor(data, paths);
        });

        const payload = {
          keys,
          ...rest,
          data: keys?.length === 1 ? { ...val[keys[0]] } : val,
        };
        processedData.push(payload);
      });
      resolve(processedData);
    } catch (err) {
      reject(processedData);
    }
  });
};

export const pieDataModifier = (obj: IKeyValue) => {
  const keys = Object.keys(obj);
  return keys.map(key => ({ name: key, value: obj[key] }));
};

export const customDataModifierForAnalytics = (obj: IKeyValue) => {
  const keys = Object.keys(obj);
  return keys.map(key => ({ name: key, value: obj[key] }));
};

export const TextMaker = (data: any, addFloor = true) => {
  return [data?.address?.floor && addFloor ? `Floor - ${data?.address?.floor}` : "", data?.address?.location, data?.address?.city, data?.address?.state, data?.address?.pincode]
    .filter(Boolean)
    .join(", ");
};

export function muskContinuesValue(value = "", till: number, pixelSize?: number, direction?: "prefix" | "suffix") {
  const pixel = pixelSize || 4.5;
  const percentage = parseInt(((window.innerWidth * till) / (100 * pixel)).toFixed(2));

  if (value && value.length > percentage && direction && direction === "prefix") {
    return "...".concat(value.substring(percentage, value.length));
  } else if (value && value.length > percentage) {
    return value.substring(0, percentage).concat("...");
  }

  return value;
}

export function isValidObject(obj: object) {
  return obj && Object.keys(obj)?.length > 0;
}

export const getLabel = (bookingType: any, orderStatus: any, orderId: string) => {
  switch (bookingType) {
    case "BOOKING" || "SCHEDULED":
      switch (orderStatus) {
        case "fulfilled":
          return {
            textColor: "teal.900",
            bg: "teal.200",
            text: "FULFILLED",
            to: `/case-history/${orderId}/case-overview`,
          };
        case "unfulfilled":
          return {
            textColor: "pink.900",
            bg: "pink.200",
            text: "UNFULFILLED",
            to: `/case-history/${orderId}/case-overview`,
          };
        case "cancelled":
          return {
            textColor: "red.900",
            bg: "red.200",
            text: "CANCELLED",
            to: `/case-history/${orderId}/case-overview`,
          };
        case "scheduled":
          return {
            textColor: "green.900",
            bg: "green.200",
            text: "SCHEDULED",
            to: `/case-history/${orderId}/case-overview`,
          };
        case "payment":
          return {
            textColor: "purple.900",
            bg: "purple.200",
            text: "PAYMENT",
            to: `/case-history/${orderId}/case-overview`,
          };
        case "dispatched":
          return {
            textColor: "orange.900",
            bg: "orange.200",
            text: "DISPATCHED",
            to: `/case-history/${orderId}/case-overview`,
          };
        case "submitted":
          return {
            textColor: "blue.900",
            bg: "blue.200",
            text: "SUBMITTED",
            to: `/case-history/${orderId}/case-overview`,
          };
        case "draft":
          return {
            textColor: "blue.900",
            bg: "blue.200",
            text: "DRAFT",
            to: `/case-history/${orderId}/case-overview`,
          };
        case "dispatching":
          return {
            textColor: "blue-grey.900",
            bg: "blue-grey.200",
            text: "DISPATCHING",
            to: `/case-history/${orderId}/case-overview`,
          };
      }
      break;
    case "ENQUIRY":
      return {
        textColor: "purple.900",
        bg: "purple.200",
        text: "ENQUIRY",
        to: `/case-enquiry/${orderId}`,
      };
    case "LEAD":
      return {
        textColor: "gray.900",
        bg: "gray.200",
        text: "LEAD",
        to: `/case-enquiry/${orderId}`,
      };
  }
};

export function convertToEpoch(time: string) {
  const epochDate = Math.floor(new Date(time).getTime() / 1000);
  return epochDate ? epochDate : 0;
}

type loggerType = "error" | "info" | "warn" | "log";

export function logger(tag: string, message: any, type: loggerType = "log") {
  const _message = typeof message !== "string" ? JSON.stringify(message) : message;
  console[type](`--> ${tag} <---: `, _message);
  return;
}

export function parseGooglePlaceAddress(response: any): Address | null {
  try {
    const formattedAddress: string | undefined = response.formatted_address;

    if (!formattedAddress) {
      throw new Error("No 'formatted_address' found in the response.");
    }

    const addressComponents: any[] = response.address_components || [];
    const address: Address = {};

    addressComponents.forEach(component => {
      const types: string[] = component.types || [];

      if (types.includes("postal_code")) {
        address.postcode = component.long_name;
      } else if (types.includes("locality")) {
        address.city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        address.state = component.long_name;
      }
      // Add more conditions as needed for other address components
    });

    address.location = formattedAddress;

    return address;
  } catch (error: any) {
    console.error("Error parsing address:", error.message);
    return {};
  }
}

export function parseGooglePlaceAddressFromMap(response: any): any | null {
  try {
    const formattedAddress: string | undefined = response.formatted_address;
    if (!formattedAddress) {
      throw new Error("No 'formatted_address' found in the response.");
    }

    const addressComponents: any[] = response.address_components || [];
    const address: Address = { location: formattedAddress };

    addressComponents.forEach(component => {
      const types: string[] = component.types || [];
      if (types.includes("postal_code")) {
        address.pincode = component.long_name;
      } else if (types.includes("locality")) {
        address.city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        address.state = component.long_name;
      } else if (types.includes("country")) {
        address.country = component.long_name;
      } else if (types.includes("premise")) {
        address.premise = component.long_name;
      } else if (types.includes("neighborhood")) {
        address.neighborhood = component.long_name;
      } else if (types.includes("sublocality_level_1") || types.includes("sublocality_level_2") || types.includes("sublocality_level_3")) {
        address.sublocality = component.long_name; // Only capturing the most specific sublocality available
      }
      // Continue adding other types if necessary
    });

    return address;
  } catch (error) {
    console.error("Error parsing address:", error);
    return null;
  }
}

export function getAddonsName(addons: any) {
  if (addons?.length > 0) {
    return addons?.reduce((str: string, addon: any) => str.concat(`${addon.type} x ${addon.quantity}, `), "");
  } else {
    return "-";
  }
}

export function voidFunction() {
  return "";
}

export function removeNullAndUndefiendOrEmtpyStringfromObject(obj: any) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
}

export function idEveryFieldPresent(obj: any) {
  return obj && Object.values(obj).every((val: any) => val !== null && val !== undefined && val !== "");
}

export function isInvalidString(text?: string) {
  return text === null || text === undefined;
}

export function handleEmptyData(data: any) {
  return data || "-";
}

export function secondsToHoursAndMinutes(milliseconds: number): string {
  const totalMinutes = milliseconds / 60; // Convert milliseconds to total minutes
  const hours = Math.floor(totalMinutes / 60); // Extract hours
  const minutes = Math.floor(totalMinutes % 60); // Extract remaining minutes

  if (hours === 0) {
    return `${minutes} min`;
  } else {
    return `${hours} hr ${minutes} min`;
  }
}

export const keyExtraction = (payload: any, key: string[]) => {
  const obj: any = {};
  key.forEach(k => {
    if (payload[k]) {
      obj[k] = payload[k];
    }
  });
  return obj;
};

export function convertToCurrency(number: number, noCurrency?: boolean): string {
  // Convert number to string and split it into integer and decimal parts
  // const num = isNaN(number) ? 0 : number / 100;
  const parts: string[] = number.toString().split(".");
  let integerPart: string = parts[0];
  const decimalPart: string = parts.length > 1 ? "." + parts[1] : "";

  // Add commas to separate thousands
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (noCurrency) {
    return integerPart + decimalPart;
  }

  // Return the formatted currency string
  return `₹${integerPart}${decimalPart}`;
}

export function calculatePercentage(baseNumber: number, percentage: number): number {
  /**
   * Calculate the percentage amount of a base number.
   * @param baseNumber The base number.
   * @param percentage The percentage.
   * @returns The percentage amount.
   */
  const percentageAmount: number = (baseNumber * percentage) / 100;
  return percentageAmount;
}

export function getValueFromURLParams(key: string): string | null {
  /**
   * Get the value from URL query parameters based on the provided key.
   * @param key The key to search for in the URL query parameters.
   * @returns The corresponding value if found, otherwise null.
   */
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
}

export function convertSecondsToTime(seconds: number, isLongForm?: boolean): string {
  /**
   * Convert seconds to a string representation of hours, minutes, and remaining seconds.
   * @param seconds The number of seconds to convert.
   * @returns A string representation of hours, minutes, and remaining seconds.
   */
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const _hour = !isLongForm ? "H" : "hour";
  const _minute = !isLongForm ? "min" : "minute";
  const _second = !isLongForm ? "sec" : "second";

  let timeString = "";
  if (hours !== 0) {
    timeString += `${hours} ${_hour}${hours > 1 ? "s" : ""}`;
  }
  if (minutes !== 0) {
    if (timeString !== "") {
      timeString += ", ";
    }
    timeString += `${minutes} ${_minute} ${minutes > 1 ? "" : ""}`;
  }
  if (remainingSeconds !== 0 || (hours === 0 && minutes === 0)) {
    if (timeString !== "") {
      timeString += ", ";
    }
    timeString += `${remainingSeconds} ${_second}${remainingSeconds > 1 ? "s" : ""}`;
  }

  return timeString;
}

export const payloadValidationCheck = (object: object, cb: (payload: any) => void) => {
  const isInvalidObj = Object.values(object)?.some(itm => itm === null || itm === undefined || itm === "");

  if (isInvalidObj) {
    logger("Invalid Payload", JSON.stringify(object), "error");
    throw Error("Invalid Payload.");
    return;
  }
  cb(object);
};

export function objToQuery(obj: { [key: string]: any }) {
  return chain(obj)
    .map((value, key) => `${key}=${value}`)
    .join("&")
    .value();
}

export function sortByDistance(obj: { [key: string]: any }) {
  return sortBy(obj, ["eta"]);
}

export const distanceFormatter = (distance: number) => {
  if (!distance) {
    return "-";
  }
  const factor = distance / 1000;

  return factor ? `${factor > 1 ? factor.toFixed(1) : factor.toFixed(2)}Km` : "-";
};

export const getIsImageType = (url: string) => {
  return IMAGE_ALLOWED_TYPE.some(itm => url?.includes(itm));
};

export const getIsAllowAssetType = (url: string) => {
  return FILE_ALLOWED_TYPE.some(itm => url?.includes(itm));
};

export function convertEpochToDate(epoch?: number, format?: string): string {
  if (!epoch) return "-";
  const date = dayjs.unix(epoch / 1000);
  return format ? date.format(format) : date.format("DD:MM:YYYY HH:mm a");
}

export function formatFileSize(bytes: number): string {
  if (bytes < 1024) {
    return bytes + " Bytes";
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
}

const slipTag = ["BTH_SLIP", "BTH slip"];
export const bthDocumentFilter = (document: any) => slipTag.includes(document.tag);
export const otherDocumentFilter = (document: any) => !slipTag.includes(document.tag);

export function maskString(str: string): string {
  if (str.length <= 4) {
    return str; // If the string length is 4 or less, return the original string
  }

  const firstTwoChars = str.substring(0, 2); // Get the first 2 characters
  const lastTwoChars = str.substring(str.length - 2); // Get the last 2 characters

  // Fill the rest of the string with asterisks
  const maskedMiddle = "*".repeat(str.length - 4);

  // Concatenate the masked string
  return `${firstTwoChars}${maskedMiddle}${lastTwoChars}`;
}

type Func = (...args: any[]) => void;

export function latestDebounce(func: Func, delay: number): Func {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context: any = this;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export function isMobileNumber(input: string): boolean {
  // Regular expression to match a mobile number that starts with 5, 6, 7, 8, or 9 and has a total of 10 digits
  const mobileNumberRegex = /^[5-9]\d{9}$/;

  // Test if the input matches the regular expression
  return mobileNumberRegex.test(input);
}

export function extractQueryParams(url: string): { [key: string]: string } {
  const queryParams: { [key: string]: string } = {};
  const urlObject = new URL(url);
  const searchParams = urlObject.searchParams;

  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}

export const removeUnderscore = (str: string) => str?.replace(/_/g, " ");

export const convertDistance = (distance: any) => parseFloat((distance / 1000)?.toString())?.toFixed(2);

export function transformAndFilterObject(input: any, omitFields = []) {
  // Combine keys to omit with any fields that have 'NA' as their value
  const keysToOmit = concat(omitFields, keys(pickBy(input, value => value === "NA")));

  // Omit specified keys and keys with 'NA' values from the original object
  const filteredInput = omit(input, keysToOmit);

  // Transform the filtered object into the desired array format
  return map(filteredInput, (value, key) => ({
    key: key,
    value: value?.split(","),
  }));
}

export function camelCaseToSpaceSeparated(str: string) {
  // Use a regular expression to insert a space before each uppercase letter
  // except for the beginning of the string
  const spacedStr = str.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Convert the result to lowercase or keep it as is based on your requirement
  return spacedStr.toLowerCase();
}

export function truncateString(str: string, limit: number): string {
  if (str.length <= limit) {
    return str;
  } else {
    return str.substring(0, limit) + "...";
  }
}

export function mockPromise(responsePayload: any): Promise<string> {
  return new Promise(resolve => {
    setTimeout(() => {
      if (responsePayload) {
        resolve(responsePayload);
      } else {
        resolve("This is a mock promise resolved successfully!");
      }
    }, 3000);
  });
}

export function toTitleCase(input: string): string {
  return input.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
}

// Define an interface for the Address
interface Address {
  location?: string;
  premise?: string;
  sublocality?: string;
  city?: string;
  state?: string;
  country?: string;
  pincode?: string;
  postcode?: string;
  neighborhood?: string;
}

// Function that takes an Address object and returns a formatted string
export function createAddressSubtitle(address: any): string {
  // Destructure the address object to extract all possible fields
  const { premise, sublocality, city, state, country, pincode } = address;

  // Build the subtitle by filtering out undefined fields
  const subtitleParts: string[] = [premise, sublocality, city, state, country, pincode].filter(part => part !== undefined);
  const subtitle: string = subtitleParts.join(", ");

  // Return the formatted string with the location as a heading and the other details as the subtitle
  return subtitle;
}

export function convertToGoogleGeo(geo: any) {
  const geoLoc = JSON.parse(JSON.stringify(geo));

  if (geo?.long) {
    const lng = geo?.long;
    geoLoc.lng = lng;
    delete geoLoc?.long;
  }
  return geoLoc;
}

export function updateAddressState(addressObj: any, waypoint: any, updateFn: any) {
  const { geo, ...address } = addressObj;

  const geoLoc = geo;
  geoLoc.long = geo?.lng;
  if (has(geoLoc, "lng")) {
    delete geoLoc["lng"];
  }

  waypoint.geo = geoLoc;
  waypoint.address = address;
  updateFn(waypoint);
}

export function generateRandom10DigitUUID(): string {
  let uuid = "";
  for (let i = 0; i < 10; i++) {
    const randomDigit = Math.floor(Math.random() * 10);
    uuid += randomDigit.toString();
  }
  return uuid;
}

type FlattenedObject = { [key: string]: any };

export function flattenObject(obj: any, parentKey: string = "", result: FlattenedObject = {}): FlattenedObject {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
        flattenObject(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key];
      }
    }
  }
  return result;
}

export const isObject = (obj: any) => obj && Object.keys(obj).length > 0;
